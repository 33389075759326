import React, { useState, useEffect } from "react";
import backgroundImage from "../img/Back.png";

const Events = ({ role }) => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newEventName, setNewEventName] = useState("");
  const [newEventDescription, setNewEventDescription] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch("https://taskback.emivn.io/api/v1/events");
      if (!response.ok) throw new Error("Ошибка загрузки событий");
      const data = await response.json();
      setEvents(data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderLoadingAnimation = () => (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-40 w-40 border-t-2 border-b-5 border-custom-yellow"></div>
    </div>
  );

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    const newEvent = { name: newEventName, description: newEventDescription };
    try {
      setIsLoading(true);
      const response = await fetch("https://taskback.emivn.io/api/v1/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newEvent),
      });

      if (!response.ok) throw new Error("Ошибка при создании события");
      await response.json();
      setNewEventName("");
      setNewEventDescription("");
      setShowModal(false);
      fetchEvents();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteEvent = (event) => {
    setItemToDelete(event);
    setShowDeleteModal(true);
  };

  const confirmDeleteEvent = async () => {
    if (!itemToDelete) return;
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://taskback.emivn.io/api/v1/events/${itemToDelete.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) throw new Error("Ошибка при удалении события");
      setItemToDelete(null);
      setShowDeleteModal(false);
      fetchEvents();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return renderLoadingAnimation();
  if (error) return <p>Error: {error}</p>;

  return (
    <div
      className="relative h-screen overflow-hidden text-white font-sans"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div className="relative h-full overflow-auto p-5 rounded-lg shadow-md bg-transparent">
        {role === 2 && (
          <div className="mb-5">
            <button
              className="w-full px-4 py-2 bg-custom-yellow text-white rounded-lg"
              onClick={() => setShowModal(true)}
            >
              Создать событие
            </button>
          </div>
        )}

        <ul className="space-y-2 pb-20">
          <div className="pb-15">
            {events.length > 0 ? (
              events.map((event, index) => (
                <li
                  key={event.id}
                  className={`bg-transparent border-2 mb-2 border-custom-yellow p-4 rounded-lg shadow-md flex flex-col items-center ${
                    index === events.length - 1 ? "pb-5" : ""
                  }`}
                >
                  <div className="text-center w-full">
                    <h3 className="text-xl font-bold">{event.name}</h3>
                    <p className="mt-2 w-full">{event.description}</p>
                  </div>
                  {role === 2 && (
                    <button
                      onClick={() => handleDeleteEvent(event)}
                      className="px-2 py-1 bg-red-600 text-white rounded-lg mt-4"
                    >
                      Удалить
                    </button>
                  )}
                </li>
              ))
            ) : (
              <p>Нет доступных событий.</p>
            )}
          </div>
          <div className="mb-15 pb-10"></div>
        </ul>

        {showModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-gray-800 p-5 shadow-md w-80 rounded-[15px]">
              <h2 className="text-lg font-bold mb-4">Создание события</h2>
              <form onSubmit={handleCreateEvent}>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Название
                  </label>
                  <input
                    type="text"
                    value={newEventName}
                    onChange={(e) => setNewEventName(e.target.value)}
                    className="border border-gray-300 p-2 rounded-md w-full text-black"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Описание
                  </label>
                  <textarea
                    value={newEventDescription}
                    onChange={(e) => setNewEventDescription(e.target.value)}
                    className="border border-gray-300 p-2 rounded-md w-full text-black"
                    rows="4"
                    required
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 bg-gray-500 text-white rounded-lg mr-2"
                  >
                    Отмена
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-custom-yellow text-white rounded-lg"
                  >
                    Создать
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showDeleteModal && itemToDelete && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-gray-800 p-5 shadow-md w-80 rounded-[15px]">
              <h2 className="text-lg font-bold mb-4">Удаление события</h2>
              <p className="mb-4">
                Вы уверены, что хотите удалить событие "{itemToDelete.name}"?
              </p>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 bg-red-500 text-white rounded-lg mr-2"
                  onClick={confirmDeleteEvent}
                >
                  Удалить
                </button>

                <button
                  type="button"
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;
