import React, { useState, useEffect } from "react";
import axios from "axios";
import backgroundImage from "../img/Back.png";

const PersonUser = ({ userId, name, username }) => {
  const [groups, setGroups] = useState([]);
  const [rating, setRating] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const ratingResponse = await axios.get(
          `https://taskback.emivn.io/api/v1/user/rating/${userId}`
        );
        setRating(ratingResponse.data);

        const groupsResponse = await axios.get(
          `https://taskback.emivn.io/api/v1/groups/user/${userId}`
        );
        setGroups(groupsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Ошибка при получении данных пользователя:", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const renderLoadingAnimation = () => (
    <div className="flex items-center justify-center h-screen bg-gray-800">
      <div className="animate-spin rounded-full h-40 w-40 border-t-2 border-b-5 border-custom-yellow"></div>
    </div>
  );

  if (loading) {
    return renderLoadingAnimation();
  }

  if (!name || !username) {
    return (
      <div className="flex items-center justify-center h-screen bg-custom-yellow">
        <div>User not found</div>
      </div>
    );
  }

  const averageRating =
    rating && rating.average_rating !== undefined
      ? Math.ceil(rating.average_rating)
      : 0;
  const taskCount =
    rating && rating.task_count !== undefined ? rating.task_count : 0;

  return (
    <div
      className="flex flex-col flex-grow rounded-[17px] overflow-y-auto p-5 box-border mb-4"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-md mb-6">
        <h1 className="text-2xl font-bold mb-4">Информация о пользователе</h1>
        <div className="mb-4">
          <span className="font-semibold">Имя: {name}</span>
        </div>
        <div className="mb-4">
          <span className="font-semibold">Юзернейм: {username}</span>
        </div>
        <div className="mb-4">
          <span className="font-semibold">Группы:</span>
          <ul className="list-disc list-inside ml-4">
            {groups.map((group, index) => (
              <li key={index}>{group.name}</li>
            ))}
          </ul>
        </div>
        <div>
          <span className="font-semibold">Рейтинг: {averageRating}</span>
        </div>
        <div>
          <span className="font-semibold">Оцененные задачи: {taskCount}</span>
        </div>
      </div>

      <div className="flex flex-col items-center mb-10">
        {!showIframe && (
          <>
            <p className="text-lg mb-4">
              Мы всячески стараемся улучшить данный сервис, мы были бы рады,
              если бы вы оставили свой комментарий!
            </p>
            <button
              onClick={() => setShowIframe(true)}
              className="bg-custom-yellow text-white py-2 px-4 rounded-lg shadow-lg"
            >
              Оставить комментарий
            </button>
          </>
        )}
      </div>

      {showIframe && (
        <div className="flex justify-center pb-20">
          <iframe
            src="https://forms.yandex.ru/cloud/66e557c05056907fc5a9d5a1/?iframe=1"
            frameBorder="0"
            name="ya-form-66e557c05056907fc5a9d5a1"
            title="Yandex Form"
            className="w-full max-w-[500px] h-[600px] bg-gray-200 border border-custom-yellow rounded-lg"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default PersonUser;
