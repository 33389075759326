// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #1c1d20;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  color: #fff;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #22c55e;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #fff;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #15803d;
}

.last-task {
  margin-bottom: 130px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MainContent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,cAAc;EACd,eAAe;EACf,cAAc;EACd,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modal {\n  background-color: #1c1d20;\n  padding: 20px;\n  border-radius: 12px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  max-width: 90%;\n  max-height: 90%;\n  overflow: auto;\n  color: #fff;\n}\n\n.modal-buttons {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 20px;\n}\n\n.modal-button {\n  margin-left: 10px;\n  padding: 10px 20px;\n  background-color: #22c55e;\n  border: none;\n  border-radius: 12px;\n  cursor: pointer;\n  font-size: 16px;\n  font-family: Arial, sans-serif;\n  color: #fff;\n  transition: background-color 0.3s ease;\n}\n\n.modal-button:hover {\n  background-color: #15803d;\n}\n\n.last-task {\n  margin-bottom: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
