// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 0px;
}

* {
  scrollbar-width: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ScrollContainer.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["::-webkit-scrollbar {\n  width: 0px;\n}\n\n* {\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
